import React, { useEffect, useState } from "react"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from '../../assets/scss/admin/index.module.scss'
import firebase from '../../utils/firebase'
import CircularProgress from "@material-ui/core/CircularProgress"
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {getReservationType} from '../../utils/reservationTypes'
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import reservationTypes from "../../utils/reservationTypes"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"

export default (props) => {
  const [reservations, setReservations] = useState([]);
  const [editReservation, setEditReservation] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchReservations = () => {
    const functions = firebase.functions();
    functions.httpsCallable("getReservations")().then(res => {
      setReservations(res.data)
    }).catch(e => {
      console.log(e);
    })
  }

  // componentDidMount
  useEffect(() => {
    fetchReservations()
  }, []);

  useEffect(() => {
    if( reservations.length > 0 ){
      setLoading(false)
    }
  }, [reservations])

  const handleEditClick = (reservation) => {
    let privateNote = reservation.privateNote ? reservation.privateNote : ''
    setEditReservation({...reservation, ...{ privateNote: privateNote }})
  }

  const setStatus = (val) => {
    editReservation.status = val
    setEditReservation({...editReservation})
  }

  const setPrivateNote = (val) => {
    editReservation.privateNote = val
    setEditReservation({...editReservation})
  }

  const handleUpdateReservation = () => {
    setLoading(true)
    const functions = firebase.functions();
    functions.httpsCallable("updateReservation")(editReservation).then(res => {
      setReservations([])
      fetchReservations()
      setEditReservation(false)
    }).catch(e => {
      console.log(e);
    })
  }

  return (
    <React.Fragment>
      <Dialog open={loading}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Dialog open={ dialogMessage !== '' }>
        <DialogContent>
          { dialogMessage }
        </DialogContent>
        <DialogActions>
          <Button onClick={ (e) => { setDialogMessage('') } } color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      { ( () => {
        if( !!editReservation ){
          return <Dialog
            open={!!editReservation}
            maxWidth="md"
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">予約情報変更</DialogTitle>
            <DialogContent>
              <div className={styles.elem}>
                <TextField
                  label="予約日"
                  value={editReservation.reserveDateStr}
                  autoComplete='off'
                  disabled
                />
              </div>
              <div className={styles.elem}>
                <TextField
                  label="申込者"
                  value={`${editReservation.user.lastName} ${editReservation.user.firstName}`}
                  autoComplete='off'
                  disabled
                />
              </div>
              <div className={styles.elem}>
                <TextField
                  label="申込日"
                  value={editReservation.createdAtStr}
                  autoComplete='off'
                  disabled
                />
              </div>
              <div className={styles.elem}>
                <TextField
                  label="利用用途、要望等"
                  value={editReservation.usage}
                  autoComplete='off'
                  multiline
                  rows={7}
                  disabled
                />
              </div>
              <div className={styles.elem}>
                <FormControl
                  className={styles.formControl}
                >
                  <InputLabel id="prefecture-label">User Type</InputLabel>
                  <Select
                    value={editReservation.status}
                    onChange={ (e) => { setStatus(e.target.value);  } }
                    inputProps={{
                      name: 'status',
                      id: 'status',
                    }}
                  >
                    { ( () => {
                      return reservationTypes.map((rt, i) => {
                        return <MenuItem key={i} value={rt.id}>{ rt.value }</MenuItem>
                      })
                    } )() }
                  </Select>
                </FormControl>
              </div>
              <div className={styles.elem}>
                <TextField
                  label="内部管理メモ"
                  value={editReservation.privateNote}
                  multiline
                  rows={7}
                  onChange={ (e) => { setPrivateNote(e.target.value) } }
                  autoComplete='off'
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={ (e) => { e.preventDefault(); setEditReservation(false) } } color="primary">
                Cancel
              </Button>
              <Button onClick={ (e) => { handleUpdateReservation() } } color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      } )() }
      <TableContainer component={Paper}>
        <Table size="small" aria-label="reservations">
          <TableHead>
            <TableRow>
              <TableCell align="left">予約日</TableCell>
              <TableCell align="left">申込者</TableCell>
              <TableCell align="left">申込日</TableCell>
              <TableCell align="left">利用用途、要望等</TableCell>
              <TableCell align="left">内部管理メモ</TableCell>
              <TableCell align="left">ステータス</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { ( () => {
              if( reservations.length === 0 ){
                return <TableRow className={styles.root} >
                  <TableCell align="center" colSpan={7} style={ {padding: '20px'} }>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              } else
                return reservations.map((reservation, index) => {
                  //console.log(format(reservation.createdAt, 'yyyy'))
                  return <TableRow className={styles.root} key={index}>
                    <TableCell align="left">{ reservation.reserveDateStr }</TableCell>
                    <TableCell align="left">{ `${reservation.user.lastName} ${reservation.user.firstName}` }</TableCell>
                    <TableCell align="left">{ reservation.createdAtStr }</TableCell>
                    <TableCell align="left">{ reservation.usage ? `${reservation.usage.toString().substring(0, 5)}...` : '' }</TableCell>
                    <TableCell align="left">{ reservation.privateNote ? `${reservation.privateNote.toString().substring(0, 5)}...` : '' }</TableCell>
                    <TableCell align="left">{ getReservationType(reservation.status).value }</TableCell>
                    <TableCell align="left">
                      <IconButton aria-label="Edit" onClick={(e) => { let r = reservation; handleEditClick(r) }}>
                        <EditIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                })
            } )() }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}