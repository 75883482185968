import React, { useEffect } from "react"
import Layout from "../../components/admin/Layout"
import Reservations from "../../components/admin/Reservations"

export default props => {
  // componentDidMount
  useEffect(() => {
  }, []);

  return (
    <div>
      <Layout>
        <Reservations />
      </Layout>
    </div>
  )
}


