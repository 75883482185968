const ReservationTypelist = [
  { id: 0, value: '申し込み' },
  { id: 1, value: '確定' },
  { id: 9, value: 'キャンセル' },
]

export function getReservationType (reservationTypeId) {
  return ReservationTypelist.find(function(reservationType){
    return reservationTypeId === reservationType.id
  })
}

export default ReservationTypelist